<template>
  <v-list-item
    class="px-2"
    >
    <v-list-item-content>
      <v-list-item-title
        class="d-flex"
        >
        <div
          >
          <div>
            {{ shipment.name }}
          </div>

          <div
            class="font-weight-regular mt-1"
            >
            <template
              v-if="shipment.name == 'Sendcargo'"
              >
              {{ shipment.details }}
            </template>

            <v-dialog
              v-else
              v-model="dialog"
              width="500"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  x-small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  >
                  Ver términos y condiciones
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  Términos y condiciones
                </v-card-title>

                <v-card-text
                  v-html="shipment.details"
                  ></v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <div
          class="text-right ml-auto"
          >
          <div>
            <template
              v-if="shipment.toPay"
              >
              <div
                class="d-flex align-center"
                >
                <div
                  class="mr-1"
                  >
                  Por pagar  
                </div>

                <v-tooltip 
                  bottom
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      size="20"
                      v-bind="attrs"
                      v-on="on"
                      >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>Se subirá comprobante donde podrá hacer seguimiento a su envío y ver el costo por pagar al momento del retiro.</span>
                </v-tooltip>
              </div>
            </template>
            <template
              v-else
              >
              {{ shipment.displayAmount }}
            </template>
          </div>

          <v-chip
            x-small
            :color="colors[shipment.state]"
            class="mt-1 rounded-0"
            >
            {{ $t('shipments.state.' + shipment.state) }}
          </v-chip>
        </div>
      </v-list-item-title>
      <v-list-item-subtitle
        class="mt-1"
        v-if="shipment.name != 'Sendcargo'"
        >
        <v-card
          v-if="shipment.ticketUrl && shipment.tracking"
          outlined
          >
          <v-card-text
            class="caption black--text pa-2 d-flex flex-column flex-md-row justify-space-between"
            >
            <div>
              <div
                class="font-weight-medium"
                >
                Número de seguimiento
              </div>
              <div>
                {{ shipment.tracking }}
              </div>
            </div>

            <div
              class="mt-2"
              >
              <v-btn
                @click="openTicket"
                x-small
                outlined
                color="secondary"
                >
                Ver comprobante de envío
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <edit
          v-if="order.state == 'completed'"
          :order="order"
          :shipment="shipment"
          ></edit>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

const Edit = () => import('@/components/shippings/Edit')

export default {
  data: () => ({
    colors: {
      pending: 'yellow',
      ready: 'blue',
      shipped: 'success'
    },
    dialog: false
  }),

  props: {
    shipment: {
      required: true,
      type: Object
    },

    order: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters(['url'])
  },

  methods: {
    openTicket () {
      window.open(this.url + this.shipment.ticketUrl)
    }
  },

  components: { Edit },
}
</script>
